import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"

const Zurek = ({ przepis }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (przepis) {
      setShow(przepis)
      const anchor = document.getElementById("zurek")
      anchor.scrollIntoView()
    }
  }, [])

  return (
    <>
      <div className="element" id="zurek">
        <StaticImage
          src="../../assets/images/produkcja/zurek.png"
          alt="zurek"
          className="elementBackground"
        />
        <div className={show ? "przepis opened" : "przepis closed"}>
          <button className="closePrzepis" onClick={() => setShow(false)}>
            &#9932;
          </button>
          <h3>przepis na żurek śląski</h3>
          <p>
            1. W garnku umieścić 400 g <b>żeberek</b> lub{" "}
            <b>kości wieprzowych</b>, zalać 2 l wody, dodać{" "}
            <b>3 liście laurowe</b>, <b>5 kulek ziela angielskiego</b>,{" "}
            <b>3 kulki pieprzu</b>, <b>kilka suszonych grzybów</b> i zagotować.
          </p>
          <p>
            2. Gdy bulion się zagotuje dodać obrane i pokrojone warzywa:{" "}
            <b>2 marchewki</b>, <b>1 korzeń pietruszki</b>,{" "}
            <b>1/2 korzenia selera</b>, <b>1 por</b>, <b>1 cebulę</b>. Całość
            gotować pod przykryciem na małym ogniu przez ok. 1 godzinę. Podczas
            gotowania dodać <b>1 łyżeczkę soli</b>.
          </p>
          <p>
            3. Wyjąć z bulionu warzywa. Następnie włożyć do garnka{" "}
            <b>500g obranych i pokrojonych w dużą kostkę ziemniaków</b>, gotować
            przez 20 minut.
          </p>
          <p>
            4. <b>Boczek</b> pokroić w drobna kostkę a <b>kiełbasę śląską</b> na
            plasterki i podsmażyć na patelni. Dodać na poatelnię posiekaną
            <b>cebulę</b> i zeszklić.
          </p>
          <p>
            5. Po ugotowaniu <b>ziemniaków</b>, wyjąć z bulionu{" "}
            <b>mięso i grzyby</b>. <b>Mięso obrać</b>, ugotowane <b>grzyby</b> i
            <b>marchewkę pokroić</b>, a następnie wrzucić z powrotem do bulionu.
            Dodać podsmażoną <b>cebulę</b> z <b>kiełbasą</b> i <b>boczkiem</b>.
            Doprawić zupę <b>majerankiem</b> i przeciśniętym przez praskę
            <b>ząbkiem czosnku</b>.
          </p>
          <p>
            6. Na koniec dodać zakwas - <b>Żurek śląski firmy EKa</b> i gotować
            jeszcze przez ok. 10 minut.
          </p>
          <p>
            7. Podawać z <b>jajkiem</b> gotowanym na twardo lub półtwardo.{" "}
          </p>
        </div>
        <div
          className={
            show
              ? "description-area image-left closed"
              : "description-area image-left opened"
          }
        >
          <div className="text-holder" lang="en">
            <h3>żurek śląski</h3>
            <p>
              Zakwas na żur, przygotowany według tradycyjnej receptury, Tylko z
              naturalnych składników. Bez konserwantów i wzmacniaczy smaku.
              Idealny na rodzinny obiad.
            </p>
          </div>
          <div
            className="produkcjaSubMenu"
            onClick={() => setShow(true)}
            onKeyDown={() => setShow(true)}
            role="button"
            tabIndex={0}
            title="Przepis na żurek"
          >
            <StaticImage
              src="../../assets/images/produkcja/submenu/przepis.png"
              alt="przepis"
              className="subMenuIcon"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Zurek
