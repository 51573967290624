import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SmalecSloiki = () => {
  return (
    <>
      <div className="element" id="smalec_w_sloiku">
        <StaticImage
          src="../../assets/images/produkcja/smalec_w_sloiku.png"
          alt="smalec_w_sloiku"
          className="elementBackground"
        />
        <div className="description-area image-left">
          <div className="text-holder" lang="en">
            <h3>smalce w słoikach</h3>
            <p>
              Produkowane metodą zapiekania, cechują się wyjątkowym, wyrazistym
              smakiem. Smalce występują w wariantach: z boczkiem, cebulowy,
              czosnkowy, domowy. Prosty skład to gwarancja produktów najwyższej
              jakości.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmalecSloiki
