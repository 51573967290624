import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

const BarszczCzerwony = ({ przepis }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (przepis) {
      setShow(przepis)
      const anchor = document.getElementById("barszcz_czerwony")
      anchor.scrollIntoView()
    }
  }, [])
  return (
    <>
      <div className="element" id="barszcz_czerwony">
        <StaticImage
          src="../../assets/images/produkcja/barszcz_czerwony.png"
          alt="barszcz_czerwony"
          className="elementBackground"
        />
        <div className={show ? "przepis opened" : "przepis closed"}>
          <button className="closePrzepis" onClick={() => setShow(false)}>
            &#9932;
          </button>
          <h3>przepis na barszcz czerwony</h3>
          <p>
            1. 20 gramów suszonych grzybów zalać wody i zostawić do namoczenia.
          </p>
          <p>
            2. Do garnka włożyć połówkę kury, dodać dwie średnie marchewki,
            korzeń pietruszki, kawałek selera i nieobrane ząbki czosnku.
          </p>
          <p>
            3. Cebulę przekrój na pół i podpiekaj chwilę na suchej patelni.
            Dodac obrane i pokrojone buraki ok 1 kg. Dodać 2 liście laurowe, 4
            ziarna ziela angielskiego, kilka ziaren pieprzu i łyżkę soli.
          </p>
          <p>
            4. Do garnka wlej dwa litry wody. Zagotować bulion a następnie
            zmniejszyć moc palnika i gotować zupę jeszcze przez dwie godziny.
          </p>
          <p>
            5. Po tym czasie wszystkie składniki zupy odcedź. Barszcz przecedźić
            przez sitko. Do wywaru dodać szklankę zakwasu - barszcz czerwony
            firmy EKa.
          </p>
          <p>
            6. Dolej również wywar z grzybów oraz wsyp szczyptę cukru i sporą
            szczyptę soli. Od tego momentu nie należy gotować barszczu. Do
            podania najlepiej delikatnie podgrzewać.
          </p>
          <p>7. Podawać z ugotowanym jajkiem.</p>
        </div>
        <div
          className={
            show
              ? "description-area image-right closed"
              : "description-area image-right opened"
          }
        >
          <div className="text-holder">
            <h3>barszcz czerwony</h3>
            <p>Zakwas na barszcz.</p>
          </div>
          <div
            className="produkcjaSubMenu"
            onClick={() => setShow(true)}
            onKeyDown={() => setShow(true)}
            role="button"
            tabIndex={0}
            title="Przepis na barszcz"
          >
            <StaticImage
              src="../../assets/images/produkcja/submenu/przepis.png"
              alt="przepis"
              className="subMenuIcon"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BarszczCzerwony
