import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

const BarszczBialy = ({ przepis }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (przepis) {
      setShow(przepis)
      const anchor = document.getElementById("barszcz_bialy")
      anchor.scrollIntoView()
    }
  }, [])
  return (
    <>
      <div className="element" id="barszcz_bialy">
        <StaticImage
          src="../../assets/images/produkcja/barszcz_bialy.png"
          alt="barszcz_bialy"
          className="elementBackground"
        />{" "}
        <div className={show ? "przepis opened" : "przepis closed"}>
          <button className="closePrzepis" onClick={() => setShow(false)}>
            &#9932;
          </button>
          <h3>przepis na barszcz biały</h3>
          <p>
            1. Do dużego garnka włożyć 500 g mięsa wieprzoweko z kością opłukane
            i pokrojone na kawałki, wlać 3 litry zimnej wody, dodać 1 i 1/2
            łyżeczki soli i gotować pod przykryciem ok. pół godziny.
          </p>
          <p>
            2. Następnie dodać obrane i opłukane warzywa z włoszczyzny, cebulę,
            wędzonkę, 2 suszone grzyby, kilka kulek pieprzu, 2 liście laurowe
            oraz 5 ziaren ziela angielskiego. Zagotować a następnie zmniejszyć
            ogień, przykryć i gotować przez ok. 1 godzinę.
          </p>
          <p>
            3. Dodać opłukaną i ponacinaną białą kiełbasę i gotować na małym
            ogniu przez ok. 20 minut.
          </p>
          <p>
            4. Dodać 2 rozgniecione ząbki czosnku. Odstawić na 15-20 minut,
            następnie przecedzić.
          </p>
          <p>
            5. Dodać zakwas na biały barszcz - firmy EKa i zagotować, dodać
            majeranek, pokrojoną na plasterki kiełbasę oraz ugotowane grzyby.
          </p>
          <p>
            6. Zupę doprawić świeżo zmielonym pieprzem i zaprawić śmietaną 18%.
          </p>
          <p>7. Podawać z ugotowanym jajkiem.</p>
        </div>
        <div
          className={
            show
              ? "description-area image-right closed"
              : "description-area image-right opened"
          }
        >
          <div className="text-holder">
            <h3>barszcz biały</h3>
            <p></p>
          </div>
          <div
            className="produkcjaSubMenu"
            onClick={() => setShow(true)}
            onKeyDown={() => setShow(true)}
            role="button"
            tabIndex={0}
            title="Przepis na barszcz biały"
          >
            <StaticImage
              src="../../assets/images/produkcja/submenu/przepis.png"
              alt="przepis"
              className="subMenuIcon"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BarszczBialy
