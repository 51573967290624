import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SideMenu from "../SideMenu"

import "../../assets/css/produkcja.css"

const Menu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/produkcja_zywnosci#sok_z_buraka"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/sok_z_buraka.png"
            alt="sok_z_buraka"
            placeholder="tracedSVG"
          />
          <h3>sok z buraka</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#zurek"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/zurek.png"
            alt="zurek"
            placeholder="tracedSVG"
          />
          <h3>żurek śląski</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#barszcz_czerwony"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/barszcz_czerwony.png"
            alt="barszcz_czerwony"
            placeholder="tracedSVG"
          />
          <h3>barszcz czerwony</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#smalec_w_sloiku"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/smalec_sloiki.png"
            alt="smalec_w_sloiku"
            placeholder="tracedSVG"
          />
          <h3>smalec w słoiku</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#barszcz_bialy"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/barszcz_bialy.png"
            alt="barszcz_bialy"
            placeholder="tracedSVG"
          />
          <h3>barszcz biały</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#smalec_smakowy"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/smalec_smakowy.png"
            alt="smalec_smakowy"
            placeholder="tracedSVG"
          />
          <h3>smalec ze skwarkami</h3>
        </AnchorLink>
        <AnchorLink
          to="/produkcja_zywnosci#watrobianka"
          className="menuElement sevenElementMenu"
        >
          <StaticImage
            src="../../assets/images/produkcja/menu/watrobianka.png"
            alt="watrobianka"
            placeholder="tracedSVG"
          />
          <h3>wątrobianka</h3>
        </AnchorLink>
      </div>
      <SideMenu base="/produkcja_zywnosci#produkcja">
        <div className="sideMenuElement">
          <h3>sok z buraka</h3>
          <AnchorLink to="/produkcja_zywnosci#sok_z_buraka">
            <StaticImage
              src="../../assets/images/produkcja/menu/sok_z_buraka.png"
              alt="sok_z_buraka"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>żurek śląski</h3>
          <AnchorLink to="/produkcja_zywnosci#zurek">
            <StaticImage
              src="../../assets/images/produkcja/menu/zurek.png"
              alt="zurek"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>barszcz czerwony</h3>
          <AnchorLink to="/produkcja_zywnosci#barszcz_czerwony">
            <StaticImage
              src="../../assets/images/produkcja/menu/barszcz_czerwony.png"
              alt="barszcz_czerwony"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>smalec w słoiku</h3>
          <AnchorLink to="/produkcja_zywnosci#smalec_w_sloiku">
            <StaticImage
              src="../../assets/images/produkcja/menu/smalec_sloiki.png"
              alt="smalec_w_sloiku"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>barszcz biały</h3>
          <AnchorLink to="/produkcja_zywnosci#barszcz_bialy">
            <StaticImage
              src="../../assets/images/produkcja/menu/barszcz_bialy.png"
              alt="barszcz_bialy"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>smalec ze skwarkami</h3>
          <AnchorLink to="/produkcja_zywnosci#smalec_smakowy">
            <StaticImage
              src="../../assets/images/produkcja/menu/smalec_smakowy.png"
              alt="smalec_smakowy"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>wątrobianka</h3>
          <AnchorLink to="/produkcja_zywnosci#watrobianka">
            <StaticImage
              src="../../assets/images/produkcja/menu/watrobianka.png"
              alt="watrobianka"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
      </SideMenu>
    </>
  )
}

export default Menu
