import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Watrobianka = () => {
  return (
    <>
      <div className="element" id="watrobianka">
        <StaticImage
          src="../../assets/images/produkcja/watrobianka.png"
          alt="watrobianka"
          className="elementBackground"
        />
        <div className="description-area image-right">
          <div className="text-holder" lang="en">
            <h3>wątrobianka</h3>
            <p>Tworzona według własnej receptury, odpowiednio doprawiona</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Watrobianka
