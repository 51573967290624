import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

const ProdukcjaTopButtons = () => {
  return (
    <div>
      <AnchorLink
        to="#smalec_w_sloiku"
        className="produkcjaTopButton buttonSmalecSloik"
      ></AnchorLink>
      <AnchorLink
        to="#zurek"
        className="produkcjaTopButton buttonZurek"
      ></AnchorLink>
      <AnchorLink
        to="#sok_z_buraka"
        className="produkcjaTopButton buttonSokZBuraka"
      ></AnchorLink>
      <AnchorLink
        to="produkcja_zywnosci#smalec_smakowy"
        className="produkcjaTopButton buttonSmalec"
      ></AnchorLink>
      <AnchorLink
        to="#barszcz_bialy"
        className="produkcjaTopButton buttonBarszczBialy"
      ></AnchorLink>
      <AnchorLink
        to="#barszcz_czerwony"
        className="produkcjaTopButton buttonBarszczCzerwony"
      ></AnchorLink>
      <AnchorLink
        to="#watrobianka"
        className="produkcjaTopButton buttonWatrobianka"
      ></AnchorLink>
    </div>
  )
}

export default ProdukcjaTopButtons
