import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SmalecSmakowy = () => {
  return (
    <>
      <div className="element" id="smalec_smakowy">
        <StaticImage
          src="../../assets/images/produkcja/smalec_smakowy.png"
          alt="smalec_smakowy"
          className="elementBackground"
        />
        <div className="description-area image-left">
          <div className="text-holder" lang="en">
            <h3>
              smalce smakowe
              <br />
              ze skwarkami
            </h3>
            <p>
              Smalce w trzech wariantach smakowych:
              <br />
              czosnkowym, cebulowym i domowym.
              <br />
              Produkowane metodą tradycyjnego wytopu,
              <br />z dodatkiem najlepszych składników,
              <br />
              stanowią doskonały dodatek do pieczywa.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmalecSmakowy
