import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Seo from "../components/Seo"
import ProdukcjaTopButtons from "../components/produkcja/ProdukcjaTopButtons"
import Menu from "../components/produkcja/Menu"
import SokZBuraka from "../components/produkcja/SokZBuraka"
import Zurek from "../components/produkcja/Zurek"
import BarszczCzerwony from "../components/produkcja/BarszczCzerwony"
import SmalecSloiki from "../components/produkcja/SmalecSloiki"
import BarszczBialy from "../components/produkcja/BarszczBialy"
import SmalecSmakowy from "../components/produkcja/SmalecSmakowy"
import Watrobianka from "../components/produkcja/Watrobianka"

// Query for Top image, Contact Form background image and page elements bg
export const query = graphql`
  {
    form: file(
      name: { eq: "produkcja" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    top: file(
      name: { eq: "produkcja" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

const ProdukcjaZywnosci = ({ location }) => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  let params = new URLSearchParams(location.search)
  let przepis_na_barszcz = params.has("przepis_na_barszcz")
  let przepis_na_barszcz_bialy = params.has("przepis_na_barszcz_bialy")
  let przepis_na_zurek = params.has("przepis_na_zurek")
  return (
    <div id="produkcja" className="page produkcjaPage">
      <Seo title="Produkcja żywności" />
      <Top image={imgTop}>
        <ProdukcjaTopButtons />
      </Top>
      <Menu />
      <SokZBuraka />
      <Zurek przepis={przepis_na_zurek} />
      <BarszczCzerwony przepis={przepis_na_barszcz} />
      <SmalecSloiki />
      <BarszczBialy przepis={przepis_na_barszcz_bialy} />
      <SmalecSmakowy />
      <Watrobianka />
      <ContactForm backgroundImage={imgForm} />
      <Footer />
    </div>
  )
}

export default ProdukcjaZywnosci
